import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import BackToShop from "../BackToShop";
import Footer from "../Footer";
import Header from "../Header";
import {apiHost} from "../../const";
import Spinner from "../Spinner";

function PaymentReturn() {
  const search = useLocation().search;
  const [paymentId, setPaymentId] = useState(new URLSearchParams(search).get("paymentId"));
  const [providerId, setProviderId] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    checkPaymentStatus()
  }, [paymentId]);

  //check najnowszy status
  const checkPaymentStatus = () => {
    if (paymentId !== undefined) {
      setLoading(true)
      fetch(apiHost + `/esim/topup/payment/status?id=${paymentId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((response) => {
          if (response.status > 299) {
            setError(true)
          } else {
            return response.json()
          }
        })
        .then(data => {
          setResult(data.status);
          setError(false)
          setLoading(false)
        })
        .catch((err) => {
          setError(true)
          console.log(err.message)
          setLoading(false)
        });
    }
  }

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24">
      <Header/>

      <div className="flex-col text-center mb-6">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Status zamówienia </h1>
        <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">
          Płatność numer: {paymentId}
        </p>
        {!loading && (
          <div>
        {result === "SUCCESS" && ( <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">Karta esim otrzymała dodatkowe pakiety danych.</p>)}
        {result === "SUCCESS_WITH_PAY_LATER" && ( <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">Karta esim otrzymała dodatkowe pakiety danych.</p>)}
        {result === "REJECTED" && ( <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">Zrezygnowano z zatwierdzenia płatności i zakup doładowania esim się nie powiódł. Środki na Twoim koncie mogły zostac zablokowane ale wciągu
          kilku dni zostaną zwrócone. Prosimy o ponowienie próby płatności lub nowe złożenie zamówienia w celu dokupienia pakietów danych do
          karty esim.</p>)}
        {result === "ERROR" && ( <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">Nastąpił problem z zaakceptowaniem płatności i zakup doładowania esim się nie powiódł. Środki na Twoim koncie mogły zostac zablokowane ale wciągu
          kilku dni zostaną zwrócone. Prosimy o ponowienie próby płatności lub nowe złożenie zamówienia w celu dokupienia pakietów danych do
          karty esim.</p>)}
        {result === "PENDING" && ( <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl">	Płatność jest przetwarzana.</p>)}
        {result !== "PENDING" && (
          <p className="text-gray-900 mt-6 text-lg leading-8 text-gray-600 max-w-3xl"> Sprawdź pocztę po dodatkowe
            informacje.</p>)}
          </div>)}
      </div>

      {loading && (<Spinner isVisible={loading}/>)}
      {error && (<p className="mt-6 text-lg leading-8 max-w-3xl text-red-700">
        Wystąpił problem z pobraniem statusu płatności {paymentId} ({providerId}). Spróbuj ponownie lub <a
        href='https://esim5.com/formularz-kontaktowy/' className="text-green-700">skontaktuj się z pomocą</a>
      </p>)}

      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={checkPaymentStatus}>
        Odśwież status
      </button>

      <BackToShop/>
      <Footer/>
    </main>
  );
}

export default PaymentReturn;
